import { Spin } from 'antd';
import { atoms } from 'shared/atoms';
import { useLocation, useNavigate } from 'react-router-dom';
import { FC } from 'react';
import { useAtomValue } from 'jotai';

export const Login: FC = () => {
  const keycloak = useAtomValue(atoms.auth.keycloakInstance);
  const location = useLocation();
  const navigate = useNavigate();

  const redirectPath = (location.state as any)?.from?.pathname || '/';
  const redirectUri = window.location.href.replace(window.location.pathname, '') + redirectPath;

  console.log(keycloak.authenticated);

  if (keycloak.authenticated) {
    navigate(redirectPath, { replace: true });
    return null;
  } else {
    keycloak.login({ redirectUri });
    return <Spin />;
  }
};
