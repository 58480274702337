//#region imports
import { Spinal } from '@/shared/components';
import { PageTitle } from '@/shared/components/page-title/pageTitle';
import { isDisabled } from '@/shared/utilities/checkDisabled';
import { faClockRotateLeft, faEye, faFloppyDisk } from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsFromLine, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faArrowsToLine, faCircleInfo, faCog, faLeftRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { TabsProps } from 'antd';
import { Button, Card, Divider, Form, Modal, Row, Space, Tabs, notification } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createProject,
  getProjectWithProjectId,
  getSiomTemplates,
  saveGeometry,
  saveGlazenStad,
  saveProjectGeneral,
  saveSiom,
} from '../../model';
import {
  AuthorizationType,
  GeometryAndConstructionType,
  GlobalForm,
  ProjectWrapperProps,
  RightsEnum,
  SiomProjectType,
  SiomTemplate,
} from '../../types/project.types';
import { Authorization } from './authorization';
import { AuthorizationModal } from './authorization/components/authorizationModal';
import { DataExchange } from './data-exchange';
import { EditProject } from './edit';
import { History } from './history';
import { NewProject } from './new';
import { getGeomertyAndConstructionItems, getGlazenStadItems, getSiomItems } from './project.data';
import styles from './project.module.scss';
import { KISCollapse, ProjectCardTitle } from './shared';
import { CalculateSiom } from './siom/calculate-siom';
//#endregion

export const ProjectWrapper: FC<ProjectWrapperProps> = ({ projectType }) => {
  //#region variables and state
  const { t } = useTranslation();
  const { projectId } = useParams();
  const [form] = Form.useForm<GlobalForm>();
  const [saving, setSaving] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [locksOpen, setLocksOpen] = useState(projectType === 'edit');
  const [siomTemplates, setSiomTemplates] = useState<SiomTemplate[]>();
  const [activeTabKey, setActiveTabKey] = useState('general');
  const [projectName, setProjectName] = useState('');
  const [internalProjectId, setProjectId] = useState('');
  const [authorization, setAuthorization] = useState<AuthorizationType>({ general: 0, geometryAndConstruction: 0, siom: 0, glazenStad: 0 });
  const [toggleClose, setToggleClose] = useState(true);
  const [isDirty, setDirty] = useState(false);
  const navigate = useNavigate();
  const [authModal, setAuthModal] = useState<{ title: string; visible: boolean; content: ReactNode }>({
    title: '',
    visible: false,
    content: null,
  });
  const [modal, setModal] = useState<{ title: string; visible: boolean; content: ReactNode }>({
    title: '',
    visible: false,
    content: null,
  });

  const abortController = useMemo(() => new AbortController(), []);
  //#endregion

  //#region on page load
  const fetchProject = useCallback(() => {
    if (!projectId) return;
    setLoading(true);
    return getProjectWithProjectId(projectId, abortController.signal)
      .then(data => {
        form.resetFields();
        form.setFieldsValue(data);
        setProjectName((data as GlobalForm).project.generalInformation.projectName);
        setProjectId((data as GlobalForm).project.generalInformation.projectId);
        setAuthorization((data as GlobalForm).authorization);
        setLoading(false);
      })
      .catch(e => {
        if (!abortController.signal.aborted) {
          notification.error({ message: e.request.response });
        }
        setLoading(false);
      });
  }, [form, projectId, abortController]);

  useEffect(() => {
    getSiomTemplates(abortController.signal)
      .then(data => {
        setSiomTemplates(data);
      })
      .catch(() => {
        if (!abortController.signal.aborted) notification.error({ message: t('siomTemplatesNotFetched') });
      });
    fetchProject();
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#endregion

  //#region save functions
  const saveGeneralTab = formValues => {
    if (!formValues.project.generalInformation) return;
    if (!projectId)
      createProject(formValues.project.generalInformation, abortController.signal)
        .then(id => {
          notification.success({ message: t('projectCreated') });
          if (typeof id === 'string') navigate(`/`);
        })
        .catch(() => {
          if (!abortController.signal.aborted) notification.error({ message: t('projectNotCreated') });
        })
        .finally(() => {
          setSaving(false);
        });
    else
      saveProjectGeneral(formValues.project.generalInformation, projectId, abortController.signal)
        .then(() => {
          setDirty(false);
          notification.success({ message: t('projectSaved') });
        })
        .catch(() => {
          if (!abortController.signal.aborted) notification.error({ message: t('projectNotSaved') });
        })
        .finally(() => setSaving(false));
  };
  const saveGeometryTab = formValues => {
    if (!projectId) return;
    const project = formValues.geometryAndConstructionProject;
    const vals = {
      geometryGenericDesign: project.geometryGenericDesign,
      innerField: project.innerField,
      partitionsInside: project.partitionsInside,
      groundAndFoundation: project.groundAndFoundation,
      liveLoads: project.liveLoads,
      cropWireSystem: project.cropWireSystem,
      serviceAndMaintenanceLoads: project.serviceAndMaintenanceLoads,
      gableEnd: project.gableEnd,
      sideWall: project.sideWall,
      aluminiumGutter: project.aluminiumGutter,
      rainwaterSystem: project.rainwaterSystem,
    } as GeometryAndConstructionType;
    saveGeometry(vals, projectId, abortController.signal)
      .then(() => {
        setDirty(false);
        notification.success({ message: t('projectSaved') });
      })
      .catch(() => {
        if (!abortController.signal.aborted) notification.error({ message: t('projectNotSaved') });
      })
      .finally(() => setSaving(false));
  };

  const saveSiomTab = formValues => {
    if (!projectId) return;
    const template = siomTemplates?.find(f => f.id === formValues.siomProject.template.id);
    const model = {
      ...formValues.siomProject,
      template: {
        filename: template?.filename,
        id: template?.id,
        templateDescription: template?.templateDescription,
        templateName: template?.templateName,
      },
    } as SiomProjectType;
    saveSiom(model, projectId, abortController.signal)
      .then(() => {
        setDirty(false);
        notification.success({ message: t('projectSaved') });
      })
      .catch(() => {
        if (!abortController.signal.aborted) notification.error({ message: t('projectNotSaved') });
      })
      .finally(() => setSaving(false));
  };

  const saveGlazenStadTab = (formValues: GlobalForm) => {
    if (!projectId) return;
    const model = {
      projectName: formValues.glazenStadProject.projectName,
      remarks: formValues.glazenStadProject.remarks,
    };
    saveGlazenStad(model, projectId, abortController.signal)
      .then(() => {
        setDirty(false);
        notification.success({ message: t('projectSaved') });
      })
      .catch(() => {
        if (!abortController.signal.aborted) notification.error({ message: t('projectNotSaved') });
      })
      .finally(() => setSaving(false));
  };
  //#endregion

  //#region utils
  const calculate = async () => {
    form
      .validateFields()
      .then(values => {
        if (isDirty) {
          if (confirm('Some may have been changed, would you like to save them and use these for the calculation?')) {
            handleSaveProject(values);
          }
          setDirty(false);
        }
        setModal({
          title: 'Calculate SIOM',
          visible: true,
          content: <CalculateSiom projectId={projectId || ''} />,
        });
      })
      .catch(() => {
        notification.error({ message: t('missingValuesCalculate') });
      });
  };

  const handleSaveProject = (formValues: GlobalForm) => {
    setSaving(true);
    switch (activeTabKey) {
      case 'general':
        saveGeneralTab(formValues);
        break;
      case 'geometryAndConstruction':
        saveGeometryTab(formValues);
        break;
      case 'siom':
        saveSiomTab(formValues);
        break;
      case 'glazenStad':
        saveGlazenStadTab(formValues);
        break;
      default:
        setSaving(false);
        break;
    }
    setLocksOpen(true);
  };

  const finishFailed = reason => {
    setSaving(true);
    switch (activeTabKey) {
      case 'general':
        if (!reason.errorFields.find(f => f.name[0] === 'project')) saveGeneralTab(reason.values);
        else {
          notification.error({ message: t('missingValues') });
          setSaving(false);
        }
        break;
      case 'geometryAndConstruction':
        if (!reason.errorFields.find(f => f.name[0] === 'geometryAndConstructionProject')) saveGeometryTab(reason.values);
        else {
          notification.error({ message: t('missingValues') });
          setSaving(false);
        }
        break;
      case 'siom':
        if (!reason.errorFields.find(f => f.name[0] === 'siomProject')) saveSiomTab(reason.values);
        else {
          notification.error({ message: t('missingValues') });
          setSaving(false);
        }
        break;
      default:
        setSaving(false);
        break;
    }
  };

  const isSaveDisabled = (auth: AuthorizationType): boolean => {
    const disabledRights = [RightsEnum.NONE, RightsEnum.READ];
    const disabledTabs = ['general', 'geometryAndConstruction', 'siom', 'glazenStad'];
    if (disabledRights.includes(auth[activeTabKey])) return true;
    if (!disabledTabs.includes(activeTabKey)) return true;
    return false;
  };
  const navigateAway = (activeKey: string) => {
    setDirty(false);
    setActiveTabKey(activeKey);
    if (activeKey === 'general' || activeKey === 'geometryAndConstruction' || activeKey === 'siom') fetchProject();
  };

  //#endregion

  //#region  tab config and operations
  const tabs: TabsProps['items'] = [
    {
      key: 'general',
      label: t('projects.generalInformation.title'),
      disabled: isDisabled(authorization.general, [RightsEnum.NONE]),
      children: projectType === 'edit' ? <EditProject auth={authorization.general} /> : <NewProject />,
    },
    {
      key: 'geometryAndConstruction',
      label: t('projects.geometryAndConstruction.title'),
      disabled: projectType === 'new' || !locksOpen || isDisabled(authorization.geometryAndConstruction, [RightsEnum.NONE]),
      children: (
        <Card
          className={styles.card}
          title={<ProjectCardTitle className={styles.title} icon={faCircleInfo} title={t('projects.geometryAndConstruction.title')} />}
          style={{ width: '100%' }}
          extra={
            <Button
              disabled={isDisabled(authorization?.geometryAndConstruction, [RightsEnum.NONE, RightsEnum.READ])}
              type="primary"
              onClick={() => setToggleClose(!toggleClose)}>
              <FontAwesomeIcon
                icon={toggleClose ? faArrowsToLine : faArrowsFromLine}
                style={{
                  marginRight: '.5rem',
                }}
              />
              {toggleClose ? t('actions.closeAll') : t('actions.openAll')}
            </Button>
          }>
          <KISCollapse
            collapseItems={getGeomertyAndConstructionItems(
              isDisabled(authorization?.geometryAndConstruction, [RightsEnum.NONE, RightsEnum.READ])
            )}
            toggleClose={toggleClose}
          />
        </Card>
      ),
    },
    {
      key: 'siom',
      label: t('projects.siom.title'),
      disabled: projectType === 'new' || !locksOpen || isDisabled(authorization.siom, [RightsEnum.NONE]),
      children: (
        <Card
          className={styles.card}
          title={<ProjectCardTitle className={styles.title} icon={faCircleInfo} title={t('projects.siom.title')} />}
          style={{ width: '100%' }}
          extra={
            <Space>
              <Button
                disabled={isDisabled(authorization?.siom, [RightsEnum.NONE, RightsEnum.READ])}
                type="primary"
                onClick={() => calculate()}>
                <FontAwesomeIcon
                  icon={faCog}
                  style={{
                    marginRight: '.5rem',
                  }}
                />
                {t('actions.calculateSiom')}
              </Button>
              <Button
                disabled={isDisabled(authorization?.siom, [RightsEnum.NONE, RightsEnum.READ])}
                type="primary"
                onClick={() => setToggleClose(!toggleClose)}>
                <FontAwesomeIcon
                  icon={toggleClose ? faArrowsToLine : faArrowsFromLine}
                  style={{
                    marginRight: '.5rem',
                  }}
                />
                {toggleClose ? t('actions.closeAll') : t('actions.openAll')}
              </Button>
            </Space>
          }>
          <KISCollapse
            collapseItems={getSiomItems(isDisabled(authorization?.siom, [RightsEnum.NONE, RightsEnum.READ]), fetchProject, siomTemplates)}
            toggleClose={toggleClose}
          />
        </Card>
      ),
    },
    {
      key: 'glazenStad',
      label: t('projects.glazenStad.title'),
      disabled: projectType === 'new' || !locksOpen || isDisabled(authorization.glazenStad, [RightsEnum.NONE]),
      children: (
        <Card
          className={styles.card}
          title={<ProjectCardTitle className={styles.title} icon={faCircleInfo} title={t('projects.glazenStad.title')} />}
          style={{ width: '100%' }}
          extra={
            <Button
              disabled={isDisabled(authorization?.glazenStad, [RightsEnum.NONE, RightsEnum.READ])}
              type="primary"
              onClick={() => setToggleClose(!toggleClose)}>
              <FontAwesomeIcon
                icon={toggleClose ? faArrowsToLine : faArrowsFromLine}
                style={{
                  marginRight: '.5rem',
                }}
              />
              {toggleClose ? t('actions.closeAll') : t('actions.openAll')}
            </Button>
          }>
          <KISCollapse
            collapseItems={getGlazenStadItems(isDisabled(authorization?.glazenStad, [RightsEnum.NONE, RightsEnum.READ]))}
            toggleClose={toggleClose}
          />
        </Card>
      ),
    },
    {
      key: 'authorization',
      label: t('projects.authorization.title'),
      disabled: projectType === 'new' || !locksOpen,
      children: (
        <Card
          className={styles.card}
          title={<ProjectCardTitle className={styles.title} icon={faEye} title={t('projects.authorization.title')} />}
          style={{ width: '100%' }}
          extra={
            <Button
              type="primary"
              onClick={() =>
                setAuthModal({
                  title: `${t('projects.authorization.newAuthorization')}`,
                  visible: true,
                  content: <AuthorizationModal type="add" />,
                })
              }>
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: '.5rem' }} />
              {t('projects.authorization.newAuthorization')}
            </Button>
          }>
          <Authorization modal={authModal} setModal={setAuthModal} />
        </Card>
      ),
    },
    {
      key: 'history',
      label: t('projects.history'),
      disabled: projectType === 'new' || !locksOpen,
      children: (
        <Card
          className={styles.card}
          title={<ProjectCardTitle className={styles.title} icon={faClockRotateLeft} title={t('projects.history')} />}
          style={{ width: '100%' }}>
          <History />
        </Card>
      ),
    },
    {
      key: 'data-exchange',
      label: t('projects.dataExchange.title'),
      disabled: projectType === 'new' || !locksOpen,
      children: <DataExchange auth={authorization?.geometryAndConstruction} />,
    },
  ];

  const operations = auth => (
    <>
      {auth === RightsEnum.OWNER && (
        <>
          <Button
            onClick={() => setActiveTabKey('authorization')}
            type="text"
            disabled={!locksOpen}
            icon={
              <>
                <FontAwesomeIcon icon={faEye} />
                &nbsp;
              </>
            }>
            {t('projects.authorization.title')}
          </Button>
          <Button
            onClick={() => setActiveTabKey('history')}
            type="text"
            disabled={!locksOpen}
            icon={
              <>
                <FontAwesomeIcon icon={faClockRotateLeft} />
                &nbsp;
              </>
            }>
            {t('projects.history')}
          </Button>
        </>
      )}
      <Button
        onClick={() => setActiveTabKey('data-exchange')}
        type="text"
        disabled={!locksOpen}
        icon={
          <>
            <FontAwesomeIcon icon={faLeftRight} />
            &nbsp;
          </>
        }>
        {t('projects.dataExchange.title')}
      </Button>
    </>
  );
  //#endregion

  return (
    <Content className={styles.project}>
      <Form
        onValuesChange={() => {
          if (activeTabKey !== 'data-exchange') setDirty(true);
        }}
        scrollToFirstError={{ behavior: 'smooth', scrollMode: 'always', skipOverflowHiddenElements: true, block: 'center' }}
        form={form}
        onFinish={handleSaveProject}
        onFinishFailed={finishFailed}>
        <Row>
          <PageTitle
            title={projectType === 'edit' ? projectName : t('projects.newProject')}
            titleAppend={projectType === 'edit' ? internalProjectId : ''}>
            <Button
              disabled={projectType === 'edit' ? isSaveDisabled(authorization) : false}
              loading={saving}
              type="primary"
              icon={
                <FontAwesomeIcon
                  icon={faFloppyDisk}
                  style={{
                    marginRight: '.6rem',
                  }}
                />
              }
              onClick={() => form.submit()}>
              {t('actions.save')}
            </Button>
          </PageTitle>
        </Row>
        <Divider />
        <Spinal initializing={false} loading={isLoading}>
          <Row>
            <Tabs
              className={styles.noTabNavMore}
              defaultActiveKey={activeTabKey}
              activeKey={activeTabKey}
              items={tabs}
              tabBarExtraContent={operations(authorization.general)}
              onChange={async activeKey => {
                if (isDirty) {
                  if (confirm('Some values may have been changed, would you like to save them?')) {
                    form
                      .validateFields()
                      .then(values => {
                        handleSaveProject(values);
                        navigateAway(activeKey);
                      })
                      .catch(() => {
                        notification.error({ message: t('missingValues') });
                        return;
                      });
                  } else {
                    navigateAway(activeKey);
                  }
                } else {
                  navigateAway(activeKey);
                }
              }}
            />
          </Row>
        </Spinal>
      </Form>
      <Modal
        width={1200}
        title={modal.title}
        open={modal.visible}
        maskClosable={false}
        destroyOnClose
        footer={false}
        onCancel={() => {
          setModal(modal => {
            return { ...modal, visible: false };
          });
        }}>
        {modal.content}
      </Modal>
    </Content>
  );
};
